import { Component, OnInit, ViewChild } from '@angular/core';
import { Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct, NgbCalendar, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { MastersService } from 'src/app/services/masters.service';
import Stepper from 'bs-stepper';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { environment } from "../../../../environments/environment";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';


@Component({
  selector: 'app-emp-create',
  templateUrl: './emp-create.component.html',
  styleUrls: ['./emp-create.component.sass']
})
export class EmpCreateComponent implements OnInit {
  private stepper: Stepper;
  isStatusActive: boolean;
  format;
  model1 = {
    left: true,
    middle: false,
  };

  model: NgbDateStruct;
  // model11: NgbDateStruct;
  // model12: NgbDateStruct;


  date: { year: number, month: number };
  @ViewChild('dp') dp: NgbDatepicker;
  time = { hour: 13, minute: 30 };
  time2 = { hour: 13, minute: 30 };

  @Input() id;
  @Input() action;
  deptlist = [];
  companyList = [];
  designationList = [];
  reportingPersonList = [];
  salaryGradeList = [];
  employeeImageData = [];
  salarySlabList = [];
  EmpForm: FormGroup;
  poupuTitle: string;
  submitted: boolean;
  myDateValue: Date;
  tableName: string;
  isPfChecked: boolean;
  isEsicChecked: boolean;
  isPtChecked: boolean;
  isflexibleChecked: boolean;
  url: any;
  maxDate: { year: number; month: number; day: number; };
  documentArray: any;
  loginId: any;

  constructor(private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public MasterService: MastersService,
    private calendar: NgbCalendar,
    private route: ActivatedRoute,
    private atp: AmazingTimePickerService,
    private modalService: NgbModal,
    private spinnerService: Ng4LoadingSpinnerService
  ) {
    const current = new Date();
    this.maxDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
  }

  ngOnInit() {
    this.myDateValue = new Date();
    this.action = this.route.snapshot.paramMap.get("action");
    this.id = this.route.snapshot.paramMap.get("id");
    const token_key = localStorage.getItem("token");
    this.loginId = localStorage.getItem("userMasterId");
    // this.EmpForm = this._formBuilder.group({
    //   StatusType : ['active', Validators.required],
    //   resignationDate : [''],
    //   leavingReason: ['']
    // })
 
    this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true
    })


    this.MasterService.getSalarySlabListForEmployee().subscribe((e: any) => {
      this.salarySlabList = e.data;
    });

    this.MasterService.getReportingPersonList(0).subscribe((e: any) => {
      // alert(this.id)
      console.log(this.id);
      this.reportingPersonList = e.data;     
      console.log(this.reportingPersonList);   
    });

    if (this.action === "Edit") {
      this.poupuTitle = "Edit Employee Master";
      this.MasterService.getEmpbyId(this.id).then(data => {
        console.log("datapatch", data.data);
        const dobdate = new Date(data.data.dob);
        const joiningDate = new Date(data.data.joiningDate);
        const confirmationDate = new Date(data.data.confirmationDate);
        const salaryEffectiveFrom = new Date(data.data.salaryEffectiveFrom);  
        const resignationDate = new Date(data.data.resignationDate);  
        const leavingDate = new Date(data.data.leavingDate);  

        this.EmpForm.patchValue(data.data);   
        this.EmpForm.controls['password'].setValue('');
        this.tableName = "department_master";
        this.MasterService.getActiveDetailsListForUpdate(data.data.departmentId, this.tableName).subscribe((e: any) => {
          this.deptlist = e.data;
        });       
        this.tableName = "company_master";
        this.MasterService.getActiveDetailsListForUpdate(data.data.companyId, this.tableName).subscribe((e: any) => {
          this.companyList = e.data;
        });

        this.tableName = "designation_master";
        this.MasterService.getActiveDetailsListForUpdate(data.data.designationId, this.tableName).subscribe((e: any) => {
          this.designationList = e.data;
        });     
        this.tableName = "salary_grade";
        this.MasterService.getActiveDetailsListForUpdate(data.data.salaryGradeId, this.tableName).subscribe((e: any) => {
          this.salaryGradeList = e.data;
        });
        console.log(this.salaryGradeList);

        this.EmpForm.controls['dob'].setValue({ year: dobdate.getFullYear(), month: dobdate.getMonth() + 1, day: dobdate.getDate() });
        this.EmpForm.controls['StatusType'].setValue(data.data.isActive);
        // this.EmpForm.controls['managerId'].setValue(data.data.managerId);

        this.EmpForm.controls['joiningDate'].setValue({ year: joiningDate.getFullYear(), month: joiningDate.getMonth() + 1, day: joiningDate.getDate() });
        this.EmpForm.controls['confirmationDate'].setValue({ year: confirmationDate.getFullYear(), month: confirmationDate.getMonth() + 1, day: confirmationDate.getDate() });
        this.EmpForm.controls['salaryEffectiveFrom'].setValue({ year: salaryEffectiveFrom.getFullYear(), month: salaryEffectiveFrom.getMonth() + 1, day: salaryEffectiveFrom.getDate() });
        this.EmpForm.controls['resignationDate'].setValue({ year: resignationDate.getFullYear(), month: resignationDate.getMonth() + 1, day: resignationDate.getDate() });
        this.EmpForm.controls['leavingDate'].setValue({ year: leavingDate.getFullYear(), month: leavingDate.getMonth() + 1, day: leavingDate.getDate() });

      
        if (data.data.isflexible == 1) {
          this.isflexibleChecked = true;
          this.EmpForm.controls['isflexibleCheckedValue'].setValue(1);
        } else {
          this.isflexibleChecked = false;
          this.EmpForm.controls['isflexibleCheckedValue'].setValue(0);
        }

        if (data.data.pfChecked == 1) {
          this.isPfChecked = true;
          this.EmpForm.controls['pfCheckedValue'].setValue(1);
        } else {
          this.isPfChecked = false;
          this.EmpForm.controls['pfCheckedValue'].setValue(0);
        }

        if (data.data.esicChecked == 1) {
          this.isEsicChecked = true;
          this.EmpForm.controls['esicCheckedValue'].setValue(1);
        } else {
          this.isEsicChecked = false;
          this.EmpForm.controls['esicCheckedValue'].setValue(0);
        }

        if (data.data.ptChecked == 1) {
          this.isPtChecked = true;
          this.EmpForm.controls['ptCheckedValue'].setValue(1);
        } else {
          this.isPtChecked = false;
          this.EmpForm.controls['ptCheckedValue'].setValue(0);
        }
        data.employeeBankDetailsData.forEach((x, index) => {
          const documentArray = this.EmpForm.controls.employeeBankDetails as FormArray;
          documentArray.push(
            this._formBuilder.group({
              id: x.id,
              bankName: x.bankName,
              banchName: x.banchName,
              branchAddress: x.branchAddress,
              accountName: x.accountName,
              accountNumber: x.accountNumber,
              accountType: x.accountType,
              ifscCode: x.ifscCode,
              micrCode: x.micrCode,
              isCheck: x.isCheck,
              isActive: x.isActive

            })
          );
        });

        data.employeeDocumentData.forEach((x, index) => {
          const documentArray = this.EmpForm.controls.employeeDocumentDetails as FormArray;
          documentArray.push(
            this._formBuilder.group({
              documentId: x.id,
              documentTitle: x.documentTitle,
              documentPathValidation: x.documentPathValidation,
              documentPath: "",
              isChecks: x.isCheck,
              isActives: x.isActive
            })
          );
        });

        
        let statusSelected =  data.data.isActive;
        if(statusSelected==='0'){
          this.isStatusActive = true;
          // this.EmpForm.get('resignationDate').setValidators([Validators.required]);
          // this.EmpForm.get('leavingDate').setValidators([Validators.required]);
          // this.EmpForm.get('leavingReason').setValidators([Validators.required]);
      console.log(" status selected");

        }else{     
          this.isStatusActive = false;
          this.EmpForm.get('resignationDate').clearValidators;
          this.EmpForm.get('leavingDate').clearValidators;
          this.EmpForm.get('leavingReason').clearValidators;
        }
      });
            
    } else if (this.action === "Add" || this.action === "add") {
      this.isPfChecked = false;
      this.isEsicChecked = false;
      this.isPtChecked = false;
      this.isflexibleChecked = false;
      this.poupuTitle = "Add Employee";
      this.tableName = "department_master";
      this.MasterService.getActiveDetailsListForCreate(this.tableName).subscribe((e: any) => {
        this.deptlist = e.data;
      });
      this.tableName = "company_master";
      this.MasterService.getActiveDetailsListForCreate(this.tableName).subscribe((e: any) => {
        this.companyList = e.data;
      });
      this.tableName = "designation_master";
      this.MasterService.getActiveDetailsListForCreate(this.tableName).subscribe((e: any) => {
        this.designationList = e.data;
      });
      this.tableName = "salary_grade";
      this.MasterService.getActiveDetailsListForCreate(this.tableName).subscribe((e: any) => {
        this.salaryGradeList = e.data;
      });

    }
    this.EmpForm = this.createEmpForm();
    this.EmpForm.controls['pfCheckedValue'].setValue(0);
    this.EmpForm.controls['esicCheckedValue'].setValue(0);
    this.EmpForm.controls['ptCheckedValue'].setValue(0);
    this.EmpForm.controls['isflexibleCheckedValue'].setValue(0);

  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.MasterService.getEmpDocmentById(this.id).then(data => {
        this.employeeImageData = data.data;
        this.employeeImageData.forEach((x, index) => {
          var url = x.documentPath;
          console.log(url);
          $('#preview_' + index).attr('src', "" + url);
        });
      });
    }, 1000);
  }


  createEmpForm(): FormGroup {
    return (this.EmpForm = this._formBuilder.group({
      firstName: ["", Validators.required],
      middleName: ["", Validators.required],
      lastName: ["", Validators.required],
      departmentId: ["", Validators.required],
      companyId: ["", Validators.required],
      designationId: ["", Validators.required],
      emailId: ["", [Validators.required, Validators.pattern("^[a-z0-9A-Z._%+-]+@[a-z0-9A-Z.-]+\\.[a-zA-Z]{2,4}$")]],
      mobileNumber: ["", [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      alternateMobileNumber: ["", [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      currentAddress: ["", Validators.required],
      permanentAddress: ["", Validators.required],
      employeeCTC: ["", Validators.required],
      employmentStatus: ["", Validators.required],
      dob: ["", Validators.required],
      username: ["", Validators.required],
      password: [""],
      salarySlabId: ["", Validators.required],
      salaryGradeId: ["", Validators.required],
      salaryEffectiveFrom: ["", Validators.required],
      joiningDate: ["", Validators.required],
      confirmationDate: ["", Validators.required],
      pfNumber: [""],
      esicNumber: [""],
      // pfNumber: ["", [Validators.pattern("[a-zA-Z]{5}[0-9]{17}")]],
      // esicNumber: ["", [Validators.pattern("^[0-9]{17}$")]],
      adharNumber: ["", [Validators.pattern("^[0-9]{12}$")]],
      panNumber: ["", [Validators.pattern("[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}")]],
      weekOff: ["", Validators.required],
      noticePeriodInDays: ["", Validators.required],
      salaryGross: ["", Validators.required],
      shiftStartTime: ["", Validators.required],
      shiftEndTime: ["", Validators.required],
      breakTimeInMinutes: ["", Validators.required],
      earlyTimeOutsInMinutes: ["", Validators.required],
      lateInTimeInMinnutes: ["", Validators.required],
      isflexibleCheckedValue: ["", Validators.required],
      pfCheckedValue: [""],
      esicCheckedValue: [""],
      ptCheckedValue: [""],
      StatusType: [""],
      resignationDate:[""],
      leavingDate:[""],
      leavingReason:[""],
      managerId: ["", Validators.required],
      // documentTitle : ["", Validators.required],
      // companyName: ["", Validators.required],
      // emailId: ["", Validators.required],
      // mobileNumber: ["", Validators.required],
      // alternateMobileNumber: ["", Validators.required],
      // pfChecked:["", Validators.required],
      // esicChecked:["", Validators.required],
      // ptChecked:["", Validators.required],
      //isflexible:["", Validators.required],
      //       password: ['', [
      //         Validators.required,
      //         Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{2,5}$/)
      //  ]]
      employeeBankDetails: this._formBuilder.array([]),
      employeeDocumentDetails: this._formBuilder.array([]),

    }));

  }

  addEmployeeBankDetails() {
    const documentArray = this.EmpForm.controls.employeeBankDetails as FormArray;
    documentArray.push(
      this._formBuilder.group({
        id: [""],
        bankName: ["", Validators.required],
        banchName: ["", Validators.required],
        branchAddress: ["", Validators.required],
        accountName: ["", Validators.required],
        accountNumber: ["", Validators.required],
        accountType: ["", Validators.required],
        ifscCode: ["", Validators.required],
        micrCode: ["", Validators.required],
        isCheck: 1,
        isActive: 1
      })
    );
  }

  deletesalaryGradeDetails(index) {
    const employeeBankDetails = this.EmpForm.controls.employeeBankDetails as FormArray;
    let projectTeamAllocationDetailsId = employeeBankDetails.value[index].projectTeamAllocationDetailsId;
    if (this.action === "Add") {
      (this.EmpForm.get('employeeBankDetails') as FormArray).removeAt(index);
      this.toastr.success("Success !! Row deleted successfully");
    } else {
      if (!(projectTeamAllocationDetailsId)) {
        (this.EmpForm.get('employeeBankDetails') as FormArray).removeAt(index);
        this.toastr.success("Success !! Row deleted successfully");
      }
      //  else {
      //   this.MasterService.deletesalaryGradeDetails(projectTeamAllocationDetailsId).subscribe((response: any) => {
      //     if (response.code == 1) {
      //       this.toastr.success("Success !", response.msg);
      //       this.router
      //         .navigateByUrl("/app/refresh", { skipLocationChange: true })
      //         .then(() => {
      //           this.router.navigate(["/project/projectCreate/" + this.id + "/Edit"]);
      //         });
      //       return true;
      //     } else if (response.code == 0) {
      //       this.toastr.error(response.msg, "Error!");
      //       return false;
      //     } else {
      //       this.toastr.error("Record not created!", "Error!");
      //       return false;
      //     }
      //   },
      //     error => {
      //       this.toastr.warning("Internal Server Error !!", "Error!");
      //       return false;
      //     }
      //   );
      // }
    }
  }

  addEmployeeDocumentDetails() {
    const documentArray = this.EmpForm.controls.employeeDocumentDetails as FormArray;
    documentArray.push(
      this._formBuilder.group({
        documentId: [""],
        documentTitle: ["", Validators.required],
        documentPathValidation: ["", Validators.required],
        documentPath: [""],
        isChecks: 1,
        isActives: 1
      })
    );
  }

  StatusChanged(index) {
    var isActive = this.EmpForm.controls['employeeBankDetails']['controls'][index]['controls']['isActive'].value;
    if (isActive == 1) {
      this.EmpForm.controls['employeeBankDetails']['controls'][index]['controls']['isActive'].setValue(0);
    } else {
      this.EmpForm.controls['employeeBankDetails']['controls'][index]['controls']['isActive'].setValue(1);
    }
  }

  documentStatusChanged(index) {
    var isActive = this.EmpForm.controls['employeeDocumentDetails']['controls'][index]['controls']['isActives'].value;
    if (isActive == 1) {
      this.EmpForm.controls['employeeDocumentDetails']['controls'][index]['controls']['isActives'].setValue(0);
    } else {
      this.EmpForm.controls['employeeDocumentDetails']['controls'][index]['controls']['isActives'].setValue(1);
    }
  }

  getControls() {
    return (this.EmpForm.get("employeeBankDetails") as FormArray).controls;
  }

  getDocumentControls() {
    return (this.EmpForm.get("employeeDocumentDetails") as FormArray).controls;
  }

  selectToday() {
    this.model = this.calendar.getToday();
  }

  setCurrent() {
    //Current Date
    this.dp.navigateTo()
  }
  setDate() {
    //Set specific date
    this.dp.navigateTo({ year: 2013, month: 2 });
  }

  navigateEvent(event) {
    this.date = event.next;
  }

  get f() {
    return this.EmpForm.controls;

  }

  onSubmit(regfrm: any) {
    this.spinnerService.show();
    this.submitted = true;
    console.log(this.EmpForm);
    // return false;
    // if (this.EmpForm.invalid) {
    //   this.toastr.error("Error !! Record not created");
    //   return;
    // }
    this.MasterService.createEmp(this.EmpForm.value).subscribe((response: any) => {
      console.log("response", response.msg);
      if (response.code === 1) {
        this.toastr.success("Employee saved successfully..!!");

        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/masters/empmaster/empmasterlist"]);
        });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );
  }

  next() {
    this.stepper.next();
  }

  previous() {
    this.stepper.previous();
  }

  UpdateProjectType() {
    this.submitted = true;
    this.EmpForm.value.id = this.id;
    console.log(this.EmpForm.value);

    if (this.EmpForm.invalid) {
      this.toastr.error("Error !! Record not updated");
      return;
    }

    this.MasterService.updateEmp(this.id, this.EmpForm.value).subscribe((response: any) => {

      console.log("response");
      if (response.code == 1) {
        this.toastr.success("Employee Updated successfully..!!");

        this.router
          .navigateByUrl("/app/refresh", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/masters/empmaster/empmasterlist"]);
          });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Record not created!", "Error!");
        return false;
      }
    },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }

  pfChecked(e: any) {
    if (e.target.checked) {
      this.EmpForm.controls['pfCheckedValue'].setValue(1);
    } else {
      this.EmpForm.controls['pfCheckedValue'].setValue(0);
    }
  }

  esicChecked(e: any) {
    if (e.target.checked) {
      this.EmpForm.controls['esicCheckedValue'].setValue(1);
    } else {
      this.EmpForm.controls['esicCheckedValue'].setValue(0);
    }
  }

  ptChecked(e: any) {
    if (e.target.checked) {
      this.EmpForm.controls['ptCheckedValue'].setValue(1);
    } else {
      this.EmpForm.controls['ptCheckedValue'].setValue(0);
    }
  }

  flexibleChecked(e: any) {
    if (e.target.checked) {
      this.EmpForm.controls['isflexibleCheckedValue'].setValue(1);
    } else {
      this.EmpForm.controls['isflexibleCheckedValue'].setValue(0);
    }
  }

  shiftStartTime(ev: any) {
    const amazingTimePicker = this.atp.open();
    amazingTimePicker.afterClose().subscribe(time => {
      this.EmpForm.controls['shiftStartTime'].setValue(time);
    });
  }
  shiftEndTime(ev: any) {
    const amazingTimePicker = this.atp.open();
    amazingTimePicker.afterClose().subscribe(time => {
      this.EmpForm.controls['shiftEndTime'].setValue(time);
    });
  }


  numberOnly(event): boolean {
    var txtId = event.target.value;
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
      return false;
    }
    return true;
  }

  calculateGrossSalary(event) {
    var ctc = event.target.value;
    var salary = parseFloat(ctc) / 12;
    this.EmpForm.controls['salaryGross'].setValue(salary.toFixed(2));
  }

  deleteemployeeBankDetails(index) {
    let employeeBank = this.EmpForm.controls.employeeBankDetails as FormArray;
    let employeeBankDetailsId = employeeBank.value[index].id;

    if (this.action === "Add") {
      (this.EmpForm.get('employeeBankDetails') as FormArray).removeAt(index);
      this.toastr.success("Success !! Row deleted successfully");
    } else {
      if (!(employeeBankDetailsId)) {
        (this.EmpForm.get('employeeBankDetails') as FormArray).removeAt(index);
        this.toastr.success("Success !! Row deleted successfully");
      } else {
        this.MasterService.deleteEmployeeBankDetails(employeeBankDetailsId).subscribe((response: any) => {
          if (response.code == 1) {
            this.toastr.success("Success !", response.msg);

            this.router
              .navigateByUrl("/app/refresh", { skipLocationChange: true })
              .then(() => {
                this.router.navigate(["masters/empmaster/empmastercreate", this.action, this.id]);
              });
            return true;
          } else if (response.code == 0) {
            this.toastr.error(response.msg, "Error!");
            return false;
          } else {
            this.toastr.error("Record not created!", "Error!");
            return false;
          }
        },
          error => {
            this.toastr.warning("Internal Server Error !!", "Error!");
            return false;
          }
        );
      }
    }
  }
  deletEemployeeDocumentDetails(index) {
  }

  onSelectFile(event, index) {
    const file = event.target.files && event.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      if (file.type.indexOf('image') > -1) {
        this.format = 'image';
        reader.onload = (event) => {
          var url = (<FileReader>event.target).result;
          $('#preview_' + index).attr('src', "" + url);
          this.EmpForm.controls['employeeDocumentDetails']['controls'][index]['controls']['documentPath'].setValue(url);
          this.EmpForm.controls['employeeDocumentDetails']['controls'][index]['controls']['documentPathValidation'].setValue(1);
        }
      }
    }
  }

  openLarge(content, index) {
    this.url = $('#preview_' + index).attr('src');
    alert(this.url)
    if (this.url) {
       this.modalService.open(content, {
        size: 'lg'
      });
    }
  }

  onCancel() {
    this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
      this.router.navigate(["/masters/empmaster/empmasterlist"]);
    });

  }

  alphabetOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8 || (charCode == 32)) {
      // this.onlyAlphabet =false;

      return true;
    } else {
      //this.onlyAlphabet =true;
      //alert("only alphabates are allowed")
      return false;
    }
  }

  numberOnlyAllow(event): boolean {
    var txtId = event.target.value;
    const charCode = (event.which) ? event.which : event.keyCode;
    console.log(charCode);
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  onStatusChnage(){
    let statusSelected = this.EmpForm.get('StatusType').value;
    if(statusSelected==='0'){
      this.isStatusActive = true;
      console.log("on status change !!! In active");
      // this.EmpForm.get('resignationDate').setValidators([Validators.required]);
      // this.EmpForm.get('leavingDate').setValidators([Validators.required]);
      // this.EmpForm.get('leavingReason').setValidators([Validators.required]);
      // this.EmpForm.controls['isFeasibilityAnalysisPaidCheckbox'].setValue(1);
 
    }else{     
      this.isStatusActive = false;
      console.log("on status change !!! Active");
      // this.EmpForm.get('resignationDate').clearValidators;
      // this.EmpForm.get('leavingDate').clearValidators;
      // this.EmpForm.get('leavingReason').clearValidators;
      // this.EmpForm.controls['isFeasibilityAnalysisPaidCheckbox'].setValue(0);
      let myDate = new Date();
      // this.EmpForm.controls['resignationDate'].setValue(myDate);
      // this.EmpForm.controls['leavingDate'].setValue(myDate);
      // this.EmpForm.controls['leavingReason'].setValue(0);
    }
  }
}
